import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import Icon from 'Components/Icon';
import Tree from 'Components/Tree';
import { expandTree, toggleNode } from 'ducks/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import sortDevices from 'utils/sortDevices';
import statusToColor from 'utils/statusToColor';
import history from '~/history';
import styles from './styles';
import Toolbar from './Toolbar';

const getPrimaryText = device => {
    return (
        <React.Fragment>
            {device.name}
        </React.Fragment>
    );
};

const getSecondaryText = device => {
    let text = device.equipment_name;
    const
        idMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'id'),
        ipMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'ip'),
        mbusMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'mbus address'),
        modbusMetadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === 'modbus id');

    if (ipMetadata) {
        text = `IP ${ipMetadata.value} (${text})`;
    } else if (mbusMetadata) {
        text = `MBus ${mbusMetadata.value} (${text})`;
    } else if (modbusMetadata) {
        text = `Modbus ${modbusMetadata.value} (${text})`;
    } else if (idMetadata) {
        text = `OneWire ${idMetadata.value} (${text})`;
    }

    return (
        <React.Fragment>
            <Icon icon={['fas', 'circle']} size="xs" color={statusToColor(device.status)} style={{ marginRight: 5 }}/>
            <b>{text}</b>
        </React.Fragment>
    );
};

const reducer = (deviceIds, devices) => {
    return deviceIds
    .map(id => devices[id])
    .filter(device => {
        if (device.parent_id === null || devices[device.parent_id].status === 'disabled') {
            return true;
        }

        return device.status !== 'disabled';
    })
    .sort(sortDevices)
    .map(device => device.id);
};

const Component = props => {
    const
        { buildingId, byParentId, classes, deviceId, devices } = props,
        { expandTree, openNodes, panelOpen, toggleNode } = props,
        rootDeviceIds = (byParentId[null] || []).reduce((acc, id) => {
            const device = devices[id] || {};

            if (device.building_id === buildingId) {
                acc.push(device.id);
            }

            return acc;
        }, []);

    useEffect(() => {
        if (deviceId) {
            expandTree(deviceId, devices);
        }
    }, []);

    const trees = reducer(rootDeviceIds, devices).map(rootDeviceId => (
            <Tree
                activeDeviceId={deviceId}
                getPrimaryText={getPrimaryText}
                getSecondaryText={getSecondaryText}
                indexedNodes={byParentId}
                key={rootDeviceId}
                nodeId={rootDeviceId}
                nodes={devices}
                openNodes={openNodes}
                selectNode={deviceId => history.push(`/devices/${deviceId}`)}
                sorter={reducer}
                toggleNode={toggleNode}
            />
        )
    );

    return (
        <div className={!panelOpen ? classes.treeExpanded : classes.treeCollapsed}>
            <ExpansionPanel classes={{ rounded: classes.rounded }} expanded={!panelOpen}>
                <ExpansionPanelSummary
                    classes={{
                        root: classes.expansionSummary,
                        expanded: classes.expansionSummaryExpanded,
                        content: classes.expansionSummaryContent
                    }}
                >
                    <Toolbar buildingId={buildingId}/>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                    <List className={classes.trees} dense disablePadding>
                        {trees}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

const mapStateToProps = state => ({
    devices: state.devices.models,
    byParentId: state.devices.byParentId,
    openNodes: state.ui.openNodes,
    panelOpen: state.ui.showBuildings
});

const mapDispatchToProps = {
    expandTree,
    toggleNode
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));

Component.propTypes = {
    classes: PropTypes.object.isRequired
};
