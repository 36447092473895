import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Marker from 'Components/Maps/Marker';
import PropTypes from 'prop-types';
import React from 'react';
import formatTimestamp from 'utils/formatTimestamp';
import generateMarker from 'utils/generateMarker';
import history from '~/history';
import theme from '~/theme';
import styles from './styles';

let DevicesTable = props => {
    const { building, classes } = props;

    if (building.devices.length === 0) {
        return (
            <div>No devices available</div>
        );
    }

    return (
        <Paper className={classes.tablePaper}>
            <Table className={classes.tableGlobal} padding="dense">
                <TableHead>
                    <TableRow classes={{ root: classes.tableRow }}>
                        <TableCell>Device Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last Status Change</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {building.devices.map(device => (
                        <TableRow key={device.id} classes={{ root: classes.tableRow }}>
                            <TableCell classes={{ root: classes.tableCell }}>{device.name}</TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>{device.status}</TableCell>
                            <TableCell classes={{ root: classes.tableCell }}>{formatTimestamp(device.status_updated_at, building.timezone)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

DevicesTable = withStyles(styles)(DevicesTable);

const mapStatusToIcon = status => {
    const { down, ignore, known_problem, ok, problem, unknown } = theme.palette.pins;

    const icons = {
        down: generateMarker(down),
        ignore: generateMarker(ignore),
        known_problem: generateMarker(known_problem),
        ok: generateMarker(ok),
        problem: generateMarker(problem),
        unknown: generateMarker(unknown)
    };

    return icons[status];
};

const getTooltip = (classes, building) => {
    return (
        <div>
            <Typography className={classes.popover} classes={{ root: classes.root }}>{building.name}</Typography>
            <DevicesTable building={building}/>
        </div>
    );
};

const onClick = buildingId => () => history.push(`/buildings/${buildingId}`);

class Component extends React.Component {
    render() {
        const { classes, position, building } = this.props;

        return (
            <Marker
                onClick={onClick(building.id)}
                position={position}
                icon={{
                    url: mapStatusToIcon(building.status),
                    scaledSize: { width: 20, height: 25 }
                }}
                tooltip={getTooltip(classes, building)}
            />
        );
    }
}

Component.propTypes = {
    building: PropTypes.object.isRequired
};

export default withStyles(styles)(Component);
