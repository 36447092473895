import { isEmpty, isFunction } from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { initialStateFactory, reducerFactory, sagasFactory } from 'utils/factories';
import actionsFactory from 'utils/factories/actions';
import { apiRequest, uploadFiles } from 'utils/request';
import { handleError } from 'utils/sagasHelpers';
import { DELETE_DEVICE, DELETE_FUTURE, DEVICE, FETCH_DEVICES, FETCH_MEASUREMENTS, FULFILLED, LOAD_BUILDING, REGENERATE_SEED_FILES, REJECTED } from './actionTypes';

const CLEAN_MEASUREMENT_DATA = 'CLEAN_MEASUREMENT_DATA';
const CREATE_DEVICE = 'CREATE_DEVICE';
const FETCH_AVAILABLE = 'FETCH_AVAILABLE';
const FETCH_BALENA_STATUS = 'FETCH_BALENA_STATUS';
const KNOWN_PROBLEM = 'KNOWN_PROBLEM';
const RECLAIM_DEVICE = 'RECLAIM_DEVICE';
const REPLACE_DEVICE = 'REPLACE_DEVICE';
const DEPLOY_WISE_BOX = 'DEPLOY_WISE_BOX';
const TOGGLE_VPN_OFF = 'TOGGLE_VPN_OFF';
const TOGGLE_VPN_ON = 'TOGGLE_VPN_ON';
const UPDATE_DEVICE = 'UPDATE_DEVICE';
const UPLOAD_CONFIG_FILE = 'UPLOAD_CONFIG_FILE';
const UPLOAD_FTTS = 'UPLOAD_FTTS';

// actions
const actions = actionsFactory(DEVICE);

actions.getBalenaStatus = id => ({
    payload: id,
    type: FETCH_BALENA_STATUS
});

actions.cleanMeasurementData = (id, from, to, callback) => ({
    meta: callback,
    payload: { from, id, to },
    type: CLEAN_MEASUREMENT_DATA
});

actions.deleteFuture = id => ({
    payload: id,
    type: DELETE_FUTURE
});

actions.fetchAvailable = () => ({
    type: FETCH_AVAILABLE
});

actions.knownProblem = (id, data, callback) => ({
    meta: callback,
    payload: { data, id },
    type: KNOWN_PROBLEM
});

actions.reclaim = (id, wise_box_id, callback) => ({
    payload: { id, wise_box_id },
    type: RECLAIM_DEVICE,
    meta: { callback }
});

actions.regenerateSeedFiles = id => ({
    payload: id,
    type: REGENERATE_SEED_FILES
});

actions.replace = (id, equipmentId) => ({
    payload: { equipmentId, id },
    type: REPLACE_DEVICE
});

actions.deploy = id => ({
    payload: id,
    type: DEPLOY_WISE_BOX
});

actions.toggleVpnOff = id => ({
    payload: id,
    type: TOGGLE_VPN_OFF
});

actions.toggleVpnOn = id => ({
    payload: id,
    type: TOGGLE_VPN_ON
});

actions.uploadConfigFile = (id, file) => ({
    payload: { id, file },
    type: UPLOAD_CONFIG_FILE
});

actions.uploadFtt = (id, files) => ({
    payload: { id, files },
    type: UPLOAD_FTTS
});

export { actions };

// reducer
const initialState = {
    ...initialStateFactory(),
    available: [],
    balenaStatus: {},
    byParentId: {}
};

const reducer = reducerFactory(DEVICE);

let byParentId;

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case FETCH_BALENA_STATUS:
            return { ...state, balenaStatus: {}, loading: true, errorMessage: '' };

        case FETCH_BALENA_STATUS + FULFILLED:
            let balenaStatus = { ...state.balenaStatus };
            balenaStatus[payload.id] = payload.data;

            return { ...state, balenaStatus: balenaStatus, loading: false, errorMessage: '' };

        case FETCH_BALENA_STATUS + REJECTED:
            return { ...state, balenaStatus: {}, loading: false, errorMessage: payload };

        case DELETE_DEVICE + FULFILLED:
            byParentId = { ...state.byParentId };

            // hammer for now - need to find a better solution
            Object.keys(byParentId).forEach(key => {
                byParentId[key] = byParentId[key].filter(id => id !== payload);
            });

            return reducer({ ...state, byParentId }, action);

        case CREATE_DEVICE + FULFILLED:
            const { id, parent_id } = payload;
            byParentId = { ...state.byParentId };

            byParentId[parent_id] = byParentId[parent_id] ? [...byParentId[parent_id], id] : [id];

            return reducer({ ...state, byParentId }, action);

        case FETCH_AVAILABLE:
            return { ...state, available: [], loading: true, errorMessage: '' };

        case FETCH_AVAILABLE + FULFILLED:
            return { ...state, available: payload, loading: false, errorMessage: '' };

        case FETCH_AVAILABLE + REJECTED:
            return { ...state, available: [], loading: false, errorMessage: payload };

        case FETCH_DEVICES + FULFILLED:
            byParentId = { ...state.byParentId };

            payload.forEach(model => {
                if (!byParentId[model.parent_id]) {
                    byParentId[model.parent_id] = [];
                }

                if (!byParentId[model.parent_id].includes(model.id)) {
                    byParentId[model.parent_id] = [...byParentId[model.parent_id], model.id];
                }
            });

            return reducer({ ...state, byParentId }, action);

        case REPLACE_DEVICE + FULFILLED:
            let models = { ...state.models, [payload.id]: payload };
            return reducer({ ...state, models, submitting: false }, action);

        case CLEAN_MEASUREMENT_DATA:
        case DELETE_FUTURE:
        case KNOWN_PROBLEM:
        case REGENERATE_SEED_FILES:
        case REPLACE_DEVICE:
        case DEPLOY_WISE_BOX:
        case TOGGLE_VPN_OFF:
        case TOGGLE_VPN_ON:
        case UPLOAD_CONFIG_FILE:
        case UPLOAD_FTTS:
            return reducer({ ...state, submitting: true }, action);

        case CLEAN_MEASUREMENT_DATA + FULFILLED:
        case CLEAN_MEASUREMENT_DATA + REJECTED:
        case DELETE_FUTURE + FULFILLED:
        case DELETE_FUTURE + REJECTED:
        case KNOWN_PROBLEM + FULFILLED:
        case KNOWN_PROBLEM + REJECTED:
        case REGENERATE_SEED_FILES + FULFILLED:
        case REGENERATE_SEED_FILES + REJECTED:
        case REPLACE_DEVICE + REJECTED:
        case DEPLOY_WISE_BOX + FULFILLED:
        case DEPLOY_WISE_BOX + REJECTED:
        case TOGGLE_VPN_OFF + FULFILLED:
        case TOGGLE_VPN_OFF + REJECTED:
        case TOGGLE_VPN_ON + FULFILLED:
        case TOGGLE_VPN_ON + REJECTED:
        case UPLOAD_CONFIG_FILE + FULFILLED:
        case UPLOAD_CONFIG_FILE + REJECTED:
        case UPLOAD_FTTS + FULFILLED:
        case UPLOAD_FTTS + REJECTED:
            return reducer({ ...state, submitting: false }, action);

        default:
            return reducer(state, action);
    }
};

// sagas
const sagas = sagasFactory(DEVICE, '/devices');

function* watchBalenaStatus() {
    yield takeLatest(FETCH_BALENA_STATUS, function* ({ payload: id }) {
        try {
            const response = yield apiRequest(`/devices/${id}/actions/balena_status`);
            yield put({ type: FETCH_BALENA_STATUS + FULFILLED, payload: { id: id, data: response.data } });
        } catch (error) {
            const errorObject = handleError(FETCH_BALENA_STATUS, error);
            yield put(errorObject);
        }
    });
}

function* watchCleanMeasurementData() {
    yield takeLatest(CLEAN_MEASUREMENT_DATA, function* ({ payload, meta: callback }) {
        try {
            yield apiRequest(`/devices/${payload.id}/clean_measurement_data`, 'POST', { from: payload.from, to: payload.to });
            yield put({ type: CLEAN_MEASUREMENT_DATA + FULFILLED });
            if (typeof callback === 'function') {
                callback();
            }
        } catch (error) {
            const errorObject = handleError(CLEAN_MEASUREMENT_DATA, error);
            yield put(errorObject);
        }
    });
}

function* watchCreateDevice() {
    yield takeLatest(CREATE_DEVICE + FULFILLED, function* ({ payload }) {
        try {
            yield put({ type: FETCH_MEASUREMENTS, payload: { device_id: payload.id } });
        } catch (error) {
            console.error(error);
        }
    });
}

function* watchDeleteFuture() {
    yield takeLatest(DELETE_FUTURE, function* ({ payload: id }) {
        try {
            yield apiRequest(`/devices/${id}/future_data`, 'DELETE');
            yield put({ type: DELETE_FUTURE + FULFILLED });
        } catch (error) {
            const errorObject = handleError(DELETE_FUTURE, error);
            yield put(errorObject);
        }
    });
}

function* watchFetchAvailable() {
    yield takeLatest(FETCH_AVAILABLE, function* () {
        try {
            const response = yield apiRequest(`/devices/available`);
            yield put({ type: FETCH_AVAILABLE + FULFILLED, payload: response.data });
        } catch (error) {
            const errorObject = handleError(FETCH_AVAILABLE, error);
            yield put(errorObject);
        }
    });
}

function* watchKnownProblem() {
    yield takeLatest(KNOWN_PROBLEM, function* ({ payload, meta: callback }) {
        try {
            const response = yield apiRequest(`/devices/${payload.id}/actions/identify_problem`, 'PUT', payload.data);
            yield put({ type: KNOWN_PROBLEM + FULFILLED });
            callback();
            yield put({ type: LOAD_BUILDING, payload: response.data.building_id });
        } catch (error) {
            const errorObject = handleError(KNOWN_PROBLEM, error);
            yield put(errorObject);
        }
    });
}

function* watchReclaim() {
    yield takeLatest(RECLAIM_DEVICE, function* ({ payload, meta }) {
        try {
            const { id, wise_box_id } = payload;
            const response = yield apiRequest(`/devices/${id}/actions/reclaim`, 'PUT', { wise_box_id });
            yield put({ type: UPDATE_DEVICE + FULFILLED, payload: response });
            if (isFunction(meta.callback)) {
                meta.callback();
            }
        } catch (error) {
            const errorObject = handleError(RECLAIM_DEVICE, error);
            yield put(errorObject);
        }
    });
}

function* watchRegenerateSeedFiles() {
    yield takeLatest(REGENERATE_SEED_FILES, function* ({ payload: id }) {
        try {
            yield apiRequest(`/devices/${id}/regenerate_seed_files`, 'POST');
            yield put({ type: REGENERATE_SEED_FILES + FULFILLED });
        } catch (error) {
            const errorObject = handleError(REGENERATE_SEED_FILES, error);
            yield put(errorObject);
        }
    });
}

function* watchReplaceDevice() {
    yield takeLatest(REPLACE_DEVICE, function* ({ payload }) {
        try {
            let data = {};
            if (payload.equipmentId) {
                data.equipment_id = payload.equipmentId;
            }
            let response = yield apiRequest(`/devices/${payload.id}/replace`, 'PUT', data);
            yield put({ type: REPLACE_DEVICE + FULFILLED, payload: response.data });
            if (!isEmpty(data)) {
                yield put({ type: LOAD_BUILDING, payload: response.data.building_id });
            }
        } catch (error) {
            const errorObject = handleError(REPLACE_DEVICE, error);
            yield put(errorObject);
        }
    });
}

function* watchDeploy() {
    yield takeLatest(DEPLOY_WISE_BOX, function* ({ payload: id }) {
        try {
            let { data: device } = yield apiRequest(`/devices/${id}/deploy`, 'POST');
            yield put({ type: DEPLOY_WISE_BOX + FULFILLED });
            yield put({ type: 'FETCH_DEPLOYS', payload: { device_id: id } });
            yield put({ type: `FETCH_DEVICE${FULFILLED}`, payload: device });
        } catch (error) {
            const errorObject = handleError(DEPLOY_WISE_BOX, error);
            yield put(errorObject);
        }
    });
}

function* watchToggleVpnOff() {
    yield takeLatest(TOGGLE_VPN_OFF, function* ({ payload: id }) {
        try {
            const response = yield apiRequest(`/devices/${id}/actions/disable_vpn`, 'PUT');
            yield put({ type: TOGGLE_VPN_OFF + FULFILLED });
            yield put({ type: FETCH_BALENA_STATUS + FULFILLED, payload: { id: id, data: response.data } });
        } catch (error) {
            const errorObject = handleError(TOGGLE_VPN_OFF, error);
            yield put(errorObject);
        }
    });
}

function* watchToggleVpnOn() {
    yield takeLatest(TOGGLE_VPN_ON, function* ({ payload: id }) {
        try {
            const response = yield apiRequest(`/devices/${id}/actions/enable_vpn`, 'PUT');
            yield put({ type: TOGGLE_VPN_ON + FULFILLED });
            yield put({ type: FETCH_BALENA_STATUS + FULFILLED, payload: { id: id, data: response.data } });
        } catch (error) {
            const errorObject = handleError(TOGGLE_VPN_ON, error);
            yield put(errorObject);
        }
    });
}

function* watchUploadConfigFile() {
    yield takeLatest(UPLOAD_CONFIG_FILE, function* ({ payload }) {
        const { file, id } = payload;
        try {
            yield uploadFiles(`/devices/${id}/file`, 'PUT', file);
            yield put({ type: UPLOAD_CONFIG_FILE + FULFILLED });
        } catch (error) {
            const errorObject = handleError(UPLOAD_CONFIG_FILE, error);
            yield put(errorObject);
        }
    });
}

function* watchUploadFtts() {
    yield takeLatest(UPLOAD_FTTS, function* ({ payload }) {
        const { files, id } = payload;
        try {
            yield uploadFiles(`/devices/${id}/ftt`, 'PUT', files);
            yield put({ type: UPLOAD_FTTS + FULFILLED });
        } catch (error) {
            const errorObject = handleError(UPLOAD_FTTS, error);
            yield put(errorObject);
        }
    });
}

sagas.push(
    watchBalenaStatus,
    watchCleanMeasurementData,
    watchCreateDevice,
    watchKnownProblem,
    watchDeleteFuture,
    watchFetchAvailable,
    watchReclaim,
    watchRegenerateSeedFiles,
    watchReplaceDevice,
    watchDeploy,
    watchToggleVpnOff,
    watchToggleVpnOn,
    watchUploadConfigFile,
    watchUploadFtts
);

export { sagas };
