import Table from 'Components/SimpleTable';
import { sortBy } from 'lodash';
import React from 'react';
import statusToColor from 'utils/statusToColor';
import history from '~/history';

const columns = [
    { label: 'Organization', dataKey: 'organization_name' },
    { label: 'Building', dataKey: 'building_name' },
    { label: 'Root Device', dataKey: 'root_device_name' },
    { label: 'Name', dataKey: 'name' },
    { label: 'Status', dataKey: 'status', transform: ({ status }) => <span style={{ color: statusToColor(status) }}>{status}</span> }
];

const Devices = props => {
    const { devices = [] } = props;

    const
        onRowClick = id => history.push(`/devices/${id}/details`),
        rows = sortBy(devices, ['organization_name', 'building_name', 'root_device_name', 'name']);

    return (
        <Table columns={columns} onRowClick={onRowClick} rows={rows} rowsPerPage={500}/>
    );
};

export default Devices;
